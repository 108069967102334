
import {ErrorTypes} from '../constants/general'

export default class NetworkError {
    constructor(type = ErrorTypes.NORMAL, code, message, additional){
        this.type = type
        this.code = code
        this.message = message
        this.additional = additional
    }
}