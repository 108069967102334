import { makeStyles } from '@material-ui/core';
import { DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme)=> ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }))

function DialogCloseableTitle(props) {
    const classes = useStyles();
    const {...other} = props;
    return (
        <DialogTitle className={classes.root} disableTypography {...other}>
            <Typography>{props.children}</Typography>
            {props.onClose ? 
                <IconButton size="small" className={classes.closeButton} onClick={props.onClose}>
                    <Close />
                </IconButton>
                :
                null
            }
        </DialogTitle>
    )
}

export default DialogCloseableTitle