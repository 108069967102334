import { Snackbar } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import {hideAlertSnackbar} from '../../reducers/uiSlice'

export default function UniSnackbarAlert(props) {
    const dispatch = useDispatch()
    const {alertObject, openAlertSnackbar} = useSelector(state => state.ui)

    return (
        <Snackbar 
            anchorOrigin={alertObject.position} 
            autoHideDuration={alertObject.message && alertObject.message.length * 100} 
            open={openAlertSnackbar}
            onClose={handleClose}
            {...props.other}
        >
            <Alert severity={alertObject.type} onClose={handleClose}>
                {alertObject.title && <AlertTitle>{alertObject.title}</AlertTitle>}
                {alertObject.message}
            </Alert>
        </Snackbar>
    )

    function handleClose(){
        dispatch(hideAlertSnackbar())
    }
}